import {atom, DefaultValue, selector} from "recoil";

const storageKey = "22e22_auth__state"

const authState = atom({
  key: 'auth_state',
  default: JSON.parse(localStorage.getItem(storageKey)),
});

const userState = atom({
  key: 'user_state',
  default: {},
});

const authSelector = selector({
  key: 'auth_selector',
  get: ({get}) => {
    return get(authState)
  },
  set: ({set}, value) => {
    if (value instanceof DefaultValue) {
      set(authState, value)
      return;
    }
    set(authState, value)
    localStorage.setItem(storageKey, JSON.stringify(value))
  }
})

const userStateSelector = selector({
  key: 'user_state_selector',
  get: ({get}) => {
    return get(userState)
  },
  set: ({set}, value) => {
    if (value instanceof DefaultValue) {
      set(userState, value)
      return;
    }
    set(userState, value)
  }
})

export { authSelector, authState, userStateSelector, userState }