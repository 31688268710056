import React, {useContext} from 'react'
import {Navigate, useLocation} from "react-router-dom"
import authContext from "../AuthContext";

const SecRoute = ({children}) => {
  const authCtx = useContext(authContext)
  const policyApproved = localStorage.getItem("22e22_policy_approved") === "true"
  let location = useLocation();
  if (!authCtx.isLoggedIn) {
    return <Navigate to={`/login?r=${location.pathname ? location.pathname : ""}`} state={{from: location}} replace/>
  }
  if (!policyApproved) {
    return <Navigate to="/policy" state={{from: location}} replace/>
  }
  return (
    <>
      {children}
    </>
  )
};

export default SecRoute;