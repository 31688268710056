import React, {createContext, useEffect} from 'react';
import {useRecoilValue, useSetRecoilState} from "recoil";
import {authSelector, authState} from "../../state/auth";
import jwt_decode from "jwt-decode";

const AuthContext = createContext({
  authToken: {},
  data: () => {},
  isLoggedIn: false,
  onLogin: (auth) => {console.warn("not implemented")},
  onLogout: () => {},
});

export const AuthContextProvider = (props) => {
  const setAuthState = useSetRecoilState(authSelector)
  const auth = useRecoilValue(authState)

  useEffect( () => {
    const storedAuth = localStorage.getItem('22e22_auth__state');
    if (storedAuth !== null && storedAuth.length > 0) {
      setAuthState(auth);
    }
  }, [setAuthState, auth]);

  const logoutHandler = () => {
    localStorage.removeItem('22e22_auth__state');
    setAuthState({});
  };

  const loginHandler = (auth) => {
    localStorage.setItem('22e22_auth__state', JSON.stringify(auth));
    setAuthState(auth);
  };

  return (
    <AuthContext.Provider
      value={{
        authToken: auth != null ? auth.token : null,
        data: () => {
          if (auth == null) {
            return {}
          }
          const decoded = jwt_decode(auth.token);
          return {
            "id": decoded.data.user.id,
            "email": auth["user_email"],
            "nicename": auth["user_nicename"],
            "displayname": auth["user_display_name"]
          }
        },
        isLoggedIn: auth != null ? (auth.token != null && auth.token.length > 0) : false,
        onLogout: logoutHandler,
        onLogin: loginHandler,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;